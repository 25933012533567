<template>
	<div class="newscenter">
		<!-- header -->
		<homePage :urlFlag="4"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_news.png">
			<div>
                <div class="container">
                    <h2>NEWS CENTER</h2> 
                    <p>集团热点 尽在于此</p>
                </div>
            </div>
        </div>
        <!-- 新闻中心 -->
        <div class="container newsList">
            <h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					新闻中心
				</span>
			</h2>
			<p class="news">NEWS CENTER</p>
            <div>
                <div class="newsBox" v-for="(val, index) of newList" :key="index" @click="jumpUrl(val.id)">
                    <img :src="val.img" alt="">
                    <div>{{ val.title }}</div>
                    <p>2021-09-04</p>
                </div>
            </div>
        </div>
        <div class="moreBox">
            <span class="icon font_family icon-jiazai--kuai"></span>加载更多
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "newscenter",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
			newList: [
				{
					img: require('@/assets/img_news_1.png'),
					title: '9810出口首单放行“四最”服务携手构建黄金通道',
					id: 1
				}, {
					img: require('@/assets/img_news_2.png'),
					title: '成都青白江-欧城记-9810清单模式-首发成功',
					id: 2
				}, {
					img: require('@/assets/img_news_3.png'),
					title: '德国馆开业',
					id: 3
				}, {
					img: require('@/assets/img_news_4.png'),
					title: '四川自贸试验区青白江片区正式启动“安智贸”试点',
					id: 4
				}, {
					img: require('@/assets/img_news_5.png'),
					title: '集结＋！跨境电商全国龙头企业齐聚西安港 西安欧城记跨境电子商务有限公司助推中欧班列(西安)集...',
					id: 5
				}
			]
		}
	},
	methods: {
		jumpUrl(id) {
			this.$router.push({
				path:'/newsDetail',
				query: {
					id: id
				}
			})
		}
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "newscenter.css";
</style>
